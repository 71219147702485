import Image from "next/image";
import cn from "clsx";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import { Link, Text } from "@components/ui";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { URL_HOME_TEST, URL_PAGE_FORMULES } from "@middleware/constants";
import Picto from "@static/images/home/picto-product-year.jpg";
import Background from "./Background/Background";
import TrustPilotHeader from "./TrustPilotHeader/TrustPilotHeader";
import style from "./HomeHeadWithVideo.module.scss";

/**
 * @typedef {import("@prismicio/client").Content.HomeHeadWithVideoSlice} HomeHeadWithVideoSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<HomeHeadWithVideoSlice>} HomeHeadWithVideoProps
 * @param { HomeHeadWithVideoProps }
 */
const HomeHeadWithVideo = ({ slice }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const showPicto = [URL_HOME_TEST].includes(router.pathname);

  const Subtitle = () => (
    <>
      {slice.primary.subtitle.split("__STARS__").map((part, index) => (
        <>
          {part}
          {index !== slice.primary.subtitle.split("__STARS__").length - 1 && (
            <PrismicImage field={slice.primary.stars} />
          )}
        </>
      ))}
    </>
  );

  return (
    <section>
      <div className={style.wrapper}>
        <Background
          videoUrl={slice.primary.video.url}
          poster={slice.primary.poster.url}
        />
        {showPicto && (
          <div className={style.productOfYear}>
            <Image src={Picto} alt="" />
          </div>
        )}
        <div className={cn("lk-wrap large", style.contentWrap)}>
          <div className={style.content}>
            <EntryTitle variant="h1">
              <PrismicRichText field={slice.primary.title} />
            </EntryTitle>
            <Text className={style.subtitle}>
              <Subtitle />
            </Text>
            <Text className={style.text}>
              <PrismicRichText field={slice.primary.text} />
            </Text>
            <div className={style.buttonTP}>
              <div className={style.action}>
                <Link
                  href={URL_PAGE_FORMULES}
                  className="default rounded maxContent simple"
                >
                  {t("buttons.discove")}
                </Link>
              </div>
              <TrustPilotHeader
                labelMobile={slice.primary.trustpilotRatingLabelMobile}
                labelDesktop={slice.primary.trustpilotRatingLabel}
                rating={slice.primary.trustpilotRating}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHeadWithVideo;
