import style from "../HomeHeadWithVideo.module.scss";

interface Props {
  poster: string;
  videoUrl: string;
}
export default function Background({ poster, videoUrl }: Props) {
  return (
    <div className={style.bg}>
      <div
        className={style.poster}
        style={{ backgroundImage: `url(${poster})` }}
      ></div>
      <div className={style.video}>
        <video autoPlay muted loop playsInline aria-hidden="true">
          <source src={videoUrl + "#t=0.1"} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
